import { React, useEffect } from "react";
import { MdCorporateFare, MdOutlineLocationOn } from "react-icons/md";
import TextInput from "../Components/global-components/CommonTextInput/TextInput";
import BannerImage from "../Components/login-components/banner-image/BannerImage";
import BottomCard from "../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../Components/login-components/logo-container/LogoContainer";
import MsHome from "../Components/Maruti-Suzuki-components/MsHome";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommonButton from "../Components/global-components/CommonButton/CommonButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { activeStepper } from "../Screens/Redux/stepperSlice";
import { formValidator, scrollIntoViewFunc } from "../Router";
import { showGlobalError } from "../Screens/Redux/globalErrorSlice";
import axios from "axios";
import { customerData } from "../Screens/Redux/userData";
import {
  coFileUpload,
  corporateProperietorName,
  coUserDataReducer,
} from "../Screens/Redux/corporateSlice";
import { getTypeByValue } from "@mui/utils/integerPropType";
import Loadingoverlay from "../Components/global-components/LoadingOverlay/Loadingoverlay";

const CorporateAddressScreen = () => {
  const {
    fullName,
    dob,
    doi,
    permanentAddress,
    permanentPincode,
    permanentCity,
    isAryaLiteServiceUsed,
  } = useSelector((state) => state.corporateSlice.panUserdata);

  const [isChecked, setIsChecked] = useState(true);
  const [isConfirmChecked, setIsConfirmChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [coAddressLineOne, setCoAddressLineOne] = useState("");
  const [coAddressLineTwo, setCoAddressLineTwo] = useState("");
  const [coState, setCoState] = useState("");
  const [coCity, setCoCity] = useState("");
  const [coPinCode, setCoPinCode] = useState("");
  const [editableState, setEditableState] = useState("");
  const [editableCity, setEditableCity] = useState("");
  const [editablePinCode, setEditablePinCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [dateOfIncorporationValue, setDateOfIncorporationValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [panValue, setPanValue] = useState("");
  const [panValidationError, setpanValidationError] = useState(null);
  const [companyNameError , setCompanyNameError] = useState("")
  const [addressError , setAddressError] = useState("")
  const [stateError , setStateError] = useState("")
  const [cityError , setCityError] = useState("")
  const [pincodeError , setPincodeError]= useState("")
  const [errors, setErrors] = useState({
    "Company name": null,
    address: null,
    state: null,
    city: null,
  });
  console.log("errors", errors)
  // const [perAddOne , setPerAddOne] = useState("");
  // const [perAddTwo , setPerAddTwo] = useState("");
  // const [perState , setPerState] = useState("");
  // const [perCity , setPerAddCity] = useState("");
  // const [perPincode , setPerPincode] = useState("");


  const { state } = useLocation();
  const { kyc_ref_id } = state || {};
  const { isErrorMessage , RadioBtnType } = state || {};
  const { anotherMode } = state || {};

  const message =
    "We could not fetch any details for the PAN and DOB in CKYC records. Kindly fill the correct detail to proceed.";
  const { isRouteAllowed } = state || {};
  const { mi_u, channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  const kycId = useSelector((state) => state.corporateSlice.addNewKycId);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropDownValue = useSelector(
    (state) => state.corporateSlice.selectedValue
  );
  const proprietorRadioValue = useSelector(
    (state) => state.corporateSlice.proprietorRadioValue
  );
  const radioValue = useSelector((state) => state.corporateSlice.radioValue);

  let correspondenceFieldArray = [
    {
      fieldLength: coAddressLineOne?.trim()?.length,
      fieldName: "Address Line 1",
    },
    {
      fieldLength: coAddressLineTwo?.trim()?.length,
      fieldName: "Address Line 2",
    },
    { fieldLength: coState?.trim()?.length, fieldName: "Correspondence State" },
    { fieldLength: coCity?.trim()?.length, fieldName: "Correspondence City" },
    {
      fieldLength: coPinCode?.trim()?.length,
      fieldName: "Correspondence Pincode",
    },
  ];

  let fillFormArray = [
    {
      fieldLength: companyNameValue?.trim()?.length,
      fieldName: "Company Name",
    },
    {
      fieldLength: addressValue?.trim()?.length,
      fieldName: " Address",
    },
    {
      fieldLength: editableState?.trim()?.length,
      fieldName: "State",
    },
    {
      fieldLength: editableCity?.trim()?.length,
      fieldName: "City",
    },
    {
      fieldLength: editablePinCode?.trim()?.length,
      fieldName: "PinCode",
    },
  ];

  function changedateformat(val) {
    const myArray = val.split("-");
    let year = myArray[0];
    let month = myArray[1];
    let day = myArray[2];
    let formatteddate = day + "-" + month + "-" + year;
    return formatteddate;
  }
  // pan Validator
  const panChangeHandler = (e) => {
    let value = e.target.value.toUpperCase();
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    value = value?.replace(/\s/g,"");
    if (value.length <= 10) {
      setPanValue(value);
      dispatch(coUserDataReducer({ panNumber: value }));
    }
    setpanValidationError(null);

    if (!regexPan.test(value)) {
      setpanValidationError("Invalid Pan Number");
    }
  };

  const coMetaDataUpdate = () => {
    setIsLoading(true);
    let data = JSON.stringify({
      correspondenceAddressOne: coAddressLineOne || addressValue,
      correspondenceAddressTwo: coAddressLineTwo,
      correspondenceState: coState || editableState,
      correspondenceCity: coCity || editableCity,
      correspondencePincode: coPinCode || editablePinCode,
      type: RadioBtnType === "GST" ? "GST" : "Pan",
      customerType: "C",
      channel: channelUrl || "",
      txnId: mi_u || "",
      kyc_id: kycId || "",
      dob: dob || doi,
      permanentCity:
        dropDownValue === "Foreign Embassy" || isErrorMessage || isAryaLiteServiceUsed
          ? editableCity
          : permanentCity,
      permanentState:
        dropDownValue === "Foreign Embassy" || isErrorMessage || isAryaLiteServiceUsed
            ? editableState:
          "",
      permanentPincode: permanentPincode || editablePinCode,
      permanentAddress:
        dropDownValue === "Foreign Embassy" || isErrorMessage || anotherMode || isAryaLiteServiceUsed
          ? addressValue
          : permanentAddress,

      name:
        dropDownValue === "Foreign Embassy" ||
          isErrorMessage ||
          dropDownValue === "Proprietorship" ||
          anotherMode
          ? companyNameValue
          : fullName,
      typeOfCompany: dropDownValue,
      isAryaLiteServiceUsed
    });
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/customer/update-customer-meta`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.success === true) {
          setIsLoading(false);
          // WHEN USER FILL FORM IN GST FLOW IS PASS TO IF STATEMENT
          if(dropDownValue!== "Foreign Embassy" && radioValue === "GST Number") {
            dispatch(activeStepper(3));
            navigate("/Success",
              {
                state: {
                  kyc_ref_id: response?.data?.data?.kyc_id,
                  successUrl: response?.data?.data?.success_url,
                  typeOfKyc: "C",
                  isRouteAllowed: true,
                },
              }
            );
          } else {
            dispatch(
              dropDownValue !== "Foreign Embassy" &&
                !isErrorMessage &&
                dropDownValue === "Proprietorship"
                ? activeStepper(3)
                : activeStepper(2)
            );
            navigate(
              dropDownValue !== "Foreign Embassy" &&
                !isErrorMessage &&
                dropDownValue !== "Proprietorship"
                ? "/Success"
                : "/documentUpload",
              {
                state: {
                  kyc_ref_id: response?.data?.data?.kyc_id,
                  successUrl: response?.data?.data?.success_url,
                  typeOfKyc: "C",
                  isRouteAllowed: true,
                },
              }
            );
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const validateCompanyName = (name) => {

    const regex = /^[a-zA-Z0-9\s\/.,-]+$/;
    console.log("company regex", regex.test(name))
    return regex.test(name);
    
  };

  const validateAddress = (address) => {
    console.log("addres ", address)
    // const regex = /^[a-zA-Z0-9\s\/.,\-$–]+$/;
    const regex = /^[a-zA-Z0-9\s\/.,-]+$/;
    console.log("addres regex", regex.test(address))
    return regex.test(address);
  };

  const validateState = (state) => {
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(state);
  };

  const validateCity = (city) => {
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(city);
  };

  const validatePincode = (pincode) => {
    let pinCodeRegex = /^[1-9][0-9\b]+$/;
    return pinCodeRegex.test(pincode);
  }

  const companyOnKeyUpCheck = (value) => {
    const regex = /^[a-zA-Z0-9\s\/.,-]+$/;    
    if (value.length && !regex.test(value)) {    
    setCompanyNameError('Only Text, "/ "," ." , "," , "-", Numbers are allowed!');  
    } else {    
    setCompanyNameError("");    
    //  return true; // No error  
    // return hasError
    }
  
    
  };
  const addressOnKeyUpCheck = (value) => {
    const regex = /^[a-zA-Z0-9\s\/.,-]+$/; 
    if (value.length && !regex.test(value)) {    
    setAddressError('Only Text, "/ "," ." , "," , "-", Numbers are allowed!');    
    } else {    
    setAddressError("");    
    }
  
    
  };

  const stateOnKeyUpCheck = (value) => {
    const regex = /^[A-Za-z\s]+$/;    
    if (value.length && !regex.test(value)) {    
    setStateError('Please enter a valid state name. Only Text and spaces are allowed!');    
    // return true; // Error exists    
    } else {    
    setStateError("");    
    // return true; // No error    
    }
  
    
  };

  const cityOnKeyUpCheck = (value) => {
    const regex = /^[A-Za-z\s]+$/;    
    if (value.length && !regex.test(value)) {    
    setCityError('Please enter a valid city name. Only Text and spaces are allowed!');    
    // return true; // Error exists    
    } else {    
    setCityError("");    
    // return true; // No error    
    }
  
    
  };
  const pincodeOnKeyUpCheck = (value) => {
    const regex = /^[1-9][0-9\b]+$/ 
    if (!regex.test(value)) {    
    setPincodeError('Please enter valid a Pincode!');    
    return true; // Error exists    
    } else {    
    setPincodeError("");    
    // return false; // No error    
    }
  
    
  };

  const handleChange = () => {
    let errorMessage = { ...errors };

    if (companyNameValue.length) {
      if (!validateCompanyName(companyNameValue)) {
        console.log("Company name validation error if...");
        errorMessage["Company name"] = 'Invalid company name';
      } else {
        errorMessage["Company name"] = null;
      }
    }
    if (addressValue.length) {
      if (!validateAddress(addressValue)) {
        console.log("address validation error if...");
        errorMessage["address"] = 'Invalid address';
      } else {
        errorMessage["address"] = null;
      }
    }
    if (editableState.length) {
      if (!validateState(editableState)) {
        console.log("state validation error if...");
        errorMessage["state"] = 'Invalid state';
      } else {
        errorMessage["state"] = null;
      }
    }

    if (editableCity.length) {
      if (!validateCity(editableCity)) {
        console.log("city validation error if...");
        errorMessage["city"] = 'Invalid city';
      } else {
        errorMessage["city"] = null;
      }
    }
    // console.log("errorMessage", errorMessage)
    setErrors(errorMessage);

  };
  const functionOne = () => {
    const emptyFieldArray = formValidator(correspondenceFieldArray);
    const fillFormEmptyFieldArray = formValidator(fillFormArray);
    const fillFormEmptyFieldArrayAryaLite = formValidator(fillFormArray.slice(1));
    if (
      // (proprietorRadioValue === "Proprietor" && !companyNameValue) ||
      (proprietorRadioValue === "Proprietor" && radioValue !== 'GST Number' && !companyNameValue) ||
      (!isChecked && proprietorRadioValue === "Proprietor" && !companyNameValue)
    ) {
      return dispatch(showGlobalError("Kindly fill Type of Entity"));
    }
    if (
      !(dateOfIncorporationValue?.trim()?.length > 0) &&
      // proprietorRadioValue === "Proprietor"
      (proprietorRadioValue === "Proprietor" && radioValue !== 'GST Number')
    ) {
      return dispatch(showGlobalError("Kindly fill date of Incorporation"));
    }
console.log({fillFormEmptyFieldArray})
    if (
      (fillFormEmptyFieldArray && isErrorMessage) ||
      // (fillFormEmptyFieldArray && proprietorRadioValue === "Proprietor")
      (fillFormEmptyFieldArray && proprietorRadioValue === "Proprietor" && radioValue !== 'GST Number')
    ) {
      setErrorMessage(
        "Please fill this detail : " +
        (fillFormEmptyFieldArray ? fillFormEmptyFieldArray : "")
      );
      scrollIntoViewFunc("errorMsg");
      return;
    }
    if(fillFormEmptyFieldArrayAryaLite && isAryaLiteServiceUsed){
      setErrorMessage(
        "Please fill this detail : " +
        (fillFormEmptyFieldArrayAryaLite ? fillFormEmptyFieldArrayAryaLite : "")
      );
      scrollIntoViewFunc("errorMsg");
      return;
    }
    if (!isChecked && emptyFieldArray) {
      setErrorMessage(
        "Please fill this detail : " +
        (isErrorMessage ? fillFormEmptyFieldArray : "") +
        emptyFieldArray
      );
      dispatch(
        showGlobalError(
          "Please fill this detail : " +
          (isErrorMessage ? fillFormEmptyFieldArray : "") +
          emptyFieldArray
        )
      );
      scrollIntoViewFunc("errorMsg");
      return;
    }
    if (isChecked && dropDownValue !== "Proprietorship") {
      coMetaDataUpdate();
      dispatch(activeStepper(2));
    }
    if (isChecked && dropDownValue === "Proprietorship" && radioValue !== 'GST Number') {
      // if (isChecked && dropDownValue === "Proprietorship") {
      dispatch(corporateProperietorName(companyNameValue));
      navigate(
        proprietorRadioValue === "Proprietorship"
          ? "/authorisedDocument"
          : "/documentUpload",
        {
          state: {
            typeOfKyc: "C",
            isRouteAllowed: true,
          },
        }
      );
    } else {
      return coMetaDataUpdate()
    }

    if (
      !isChecked &&
      dropDownValue === "Proprietorship" &&
      coAddressLineOne?.trim()?.length > 0 &&
      coAddressLineTwo?.trim()?.length > 0 &&
      coState?.trim()?.length > 0 &&
      coCity?.trim()?.length > 0 &&
      coPinCode?.trim()?.length > 0 &&
      isConfirmChecked
    ) {
      dispatch(corporateProperietorName(companyNameValue));
      navigate(
        proprietorRadioValue === "Proprietorship"
          ? "/authorisedDocument"
          : "/documentUpload",
        {
          state: {
            typeOfKyc: "C",
            isRouteAllowed: true,
          },
        }
      );
    }
    // WHEN USER DATA FETCH FROM SINZY API NEXT CONDITION RUNNING
    if (
      !isChecked &&
      coAddressLineOne?.trim()?.length > 0 &&
      coAddressLineTwo?.trim()?.length > 0 &&
      coState?.trim()?.length > 0 &&
      coCity?.trim()?.length > 0 &&
      coPinCode?.trim()?.length > 0 &&
      isConfirmChecked
    ) {
      if (dropDownValue !== "Proprietorship" ) coMetaDataUpdate();
      dispatch(activeStepper(2));
    }
  };

  const functionTwo = () => {
    const fillFormEmptyFieldArray = formValidator(fillFormArray);
    const emptyFieldArray = formValidator(correspondenceFieldArray);
    handleChange()


    if (
      (radioValue === "Ckyc" || radioValue === "Cin") &&
      (panValidationError === "Invalid Pan Number" || panValue.length < 10)
    ) {
      return dispatch(showGlobalError("Kindly fill PAN no. of Entity"));
    }

    if (!(dateOfIncorporationValue?.trim()?.length > 0) && anotherMode) {
      return dispatch(showGlobalError("Kindly fill date of Incorporation"));
    }

    if (fillFormEmptyFieldArray) {
      setErrorMessage(
        "Please fill this detail : " +
        (fillFormEmptyFieldArray ? fillFormEmptyFieldArray : "")
      );
      scrollIntoViewFunc("errorMsg");
      return;
    }
    if (!isChecked && emptyFieldArray) {
      setErrorMessage(
        "Please fill this detail : " +
        (fillFormEmptyFieldArray ? fillFormEmptyFieldArray : "") +
        emptyFieldArray
      );
      dispatch(
        showGlobalError(
          "Please fill this detail : " +
          (fillFormEmptyFieldArray ? fillFormEmptyFieldArray : "") +
          emptyFieldArray
        )
      );
      scrollIntoViewFunc("errorMsg");
      return;
    }

    if (errors["Company name"] || errors.address || errors.state || errors.city)
      return
    if (
      isChecked &&
      companyNameValue?.trim().length > 0 &&
      editableCity?.trim().length > 0 &&
      editableState?.trim().length > 0 &&
      editablePinCode?.trim().length > 0 &&
      addressValue?.trim().length > 0
    ) {
      if(RadioBtnType === "GST") {
        return coMetaDataUpdate()
      } else {
        return navigate("/documentUpload", { state: { isRouteAllowed: true } });
      }
    }

    if (
      anotherMode
        ? dateOfIncorporationValue?.trim().length > 0 &&
        !isChecked &&
        coAddressLineOne?.trim().length > 0 &&
        coAddressLineTwo?.trim().length > 0 &&
        coState?.trim().length > 0 &&
        coCity?.trim().length > 0 &&
        coPinCode?.trim().length > 0 &&
        companyNameValue?.trim().length > 0 &&
        addressValue?.trim().length > 0 &&
        isConfirmChecked
        : !isChecked &&
        coAddressLineOne?.trim().length > 0 &&
        coAddressLineTwo?.trim().length > 0 &&
        coState?.trim().length > 0 &&
        coCity?.trim().length > 0 &&
        coPinCode?.trim().length > 0 &&
        companyNameValue?.trim().length > 0 &&
        addressValue?.trim().length > 0 &&
        isConfirmChecked
    ) {
      return navigate("/documentUpload", { state: { isRouteAllowed: true } });
    }
  };

  // const pinCodeValidator = (value) => {
  //   let pinCodeRegex = /^[0-9\b]+$/;
  //   if (pinCodeRegex.test(value)) {
  //     // setCoPinCode(value);
  //     // setEditablePinCode(value);
  //     return value;
  //   }
  //   if (!pinCodeRegex.test(value)) {
  //     return "";
  //   }
  // };
  const pinCodeValidator = (value) => {
    let pinCodeRegex = /^[0-9\b]+$/;
    if (pinCodeRegex.test(value)) {
      if(value.startsWith("0") || value.length < 6){
        setPincodeError('Please enter valid a 6 digit Pincode!'); 
        // return value;
      }
      else{
        setPincodeError("");
        return value;
      }
      // setCoPinCode(value);
      // setEditablePinCode(value);
      // setPincodeError('Please enter valid a Pincode!');  
      
    }
    if (!pinCodeRegex.test(value)) {
      // setPincodeError("");  
      return "";
    }
  };

  const correspondenceOnchangeHandler = (e) => {

    if(e.target.checked===true) {

      dispatch(
        coUserDataReducer({
          correspondenceAddressLineOne:
            "",correspondenceAddressLineTwo:"",correspondenceState:"",correspondenceCity:"",correspondencePincode:""
        })
      );
      setCoAddressLineOne()
      setCoAddressLineTwo()
      setCoCity()
      setCoPinCode()
      setCoState()
    }
    setIsChecked(e.target.checked);
  };
  const confirmcheckedOnChangeHandler = (e) => {
    setIsConfirmChecked(e.target.checked);
  };

  const continueHandler = () => {
    // console.log("isAryaLiteServiceUsed" , isAryaLiteServiceUsed)
    // if(isAryaLiteServiceUsed) {
    //   return aryaResponseHandler()
    // }

    if (
      dropDownValue !== "Foreign Embassy" &&
      !isErrorMessage &&
      !anotherMode
    ) {
      return functionOne();
    }
    if (dropDownValue === "Foreign Embassy" || isErrorMessage || anotherMode) {
      return functionTwo();
    }


  };



  const maskDetails = (address) =>{

    if (!address) {
      return "";
    }
    if(address.length<=3){
      return address
    }
let changedAddress=address.split(" ").map((str,index)=>{
    let pincodeIndex=str.search(/\d{6}/)
    if(pincodeIndex !==-1){
          let pincode =str.slice(pincodeIndex,6)
          console.log("pincode",pincode)
          let splicedAddress=address.split(" ")
        let start=str.slice(0,3)
        let end=Array.from(str.slice(3)).map(char=> char.replace(char,"*")).join("")
        return start+end
    }else{
        let end=Array.from(str).map(char=> char.replace(char,"*")).join("")
        return  index==0 || index===1 ? str : end
      }
    }).join(" ")
    return changedAddress
  }

  // aryaResponseHandler HANDLER USED WHEN RESPONSE COMES FRFOM ARYA 
  // function aryaResponseHandler() {
  //   setIsLoading(true);
  //   let data = JSON.stringify({
  //     // correspondenceAddress
  //     correspondenceAddressOne: coAddressLineOne || perAddOne,
  //     correspondenceAddressTwo: coAddressLineTwo || perAddTwo,
  //     correspondenceState: coState || perState,
  //     correspondenceCity: coCity || perCity,
  //     correspondencePincode: coPinCode || perPincode,
  //     // permamnetAddress
  //     permanentAddressOne: perAddOne,
  //     permanentAddressTwo: perAddTwo,
  //     permanentState: perState,
  //     permanentCity: perCity,
  //     permanentPincode: perPincode,
  //     type: "Pan",
  //     customerType: "C",
  //     channel: channelUrl || "",
  //     txnId: mi_u || "",
  //     kyc_id: kycId || "",
  //     dob: dob || doi,
  //     name: companyNameValue || fullName,
  //     typeOfCompany: dropDownValue,
  //     isAryaLiteServiceUsed,
  //   });
  //   let config = {
  //     method: "patch",
  //     url: `${process.env.REACT_APP_BASE_URL}/customer/update-customer-meta`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       txnId: localStorage.getItem("txnId") === "null" ?? null,
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       if (response?.data?.success === true) {
  //         setIsLoading(false);
  //         if(dropDownValue!== "Foreign Embassy" && radioValue === "GST Number") {
  //           dispatch(activeStepper(3));
  //           navigate("/Success",
  //             {
  //               state: {
  //                 kyc_ref_id: response?.data?.data?.kyc_id,
  //                 successUrl: response?.data?.data?.success_url,
  //                 typeOfKyc: "C",
  //                 isRouteAllowed: true,
  //               },
  //             }
  //           );
  //         } else {
  //         dispatch(
  //           dropDownValue !== "Foreign Embassy" &&
  //             !isErrorMessage &&
  //             dropDownValue === "Proprietorship"
  //             ? activeStepper(3)
  //             : activeStepper(2)
  //         );
  //         navigate(
  //           dropDownValue !== "Foreign Embassy" &&
  //             !isErrorMessage &&
  //             dropDownValue !== "Proprietorship"
  //             ? "/Success"
  //             : "/documentUpload",
  //           {
  //             state: {
  //               kyc_ref_id: response?.data?.data?.kyc_id,
  //               successUrl: response?.data?.data?.success_url,
  //               typeOfKyc: "C",
  //               isRouteAllowed: true,
  //             },
  //           }
  //         );
  //       }
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // }

  useEffect(() => {
    dispatch(activeStepper(1));
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [isChecked]);

  useEffect(() => {
    // disable browser button
    if (!anotherMode) {
      return () => {
        setTimeout(() => {
          window.history.forward();
        }, 0);
      };
    }
  }, []);

  useEffect(() => {
    if (!state) navigate("/login");
  }, [navigate, state]);
  const selectedVal = "Foreign Embassy";

  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <>
          {isRouteAllowed && (
            <div className="h-full flex flex-col" id="coAddressScreen">
              <MsHome>
                <BannerImage>
                  <LogoContainer />
                </BannerImage>
                <div className="bg-[#FCE9E9] h-full">
                  <BottomCard>
                    <div className="  md:w-[100%] md:h-[80%] overflow-scroll md:rounded-md  md:p-[0px_16px_5px_0px]">
                      <div className=" p-2 md:p-0">
                        <label>
                          <b>Name of Entity</b>
                        </label>
                        {dropDownValue === selectedVal ||
                          isErrorMessage ||
                          anotherMode ||
                          // proprietorRadioValue === "Proprietor"||
                          (proprietorRadioValue === "Proprietor" &&
                            radioValue !== 'GST Number'
                          )
                          ?  (<>
                            <TextInput
                            id={"CompanyName"}
                            value={companyNameValue}
                            placeholder={"Company Name"}
                            onChange={(e) => {
                            const newValue = e.target.value;
                            if (!companyOnKeyUpCheck(newValue)) {
                              setCompanyNameValue(newValue); // Update state first
                              dispatch(corporateProperietorName(newValue));
                              dispatch(coUserDataReducer({ name: newValue }));
                            }
  
                            }}
                            onKeyDown={(e) => {
                              companyOnKeyUpCheck(companyNameValue); // Run validation on keyDown
  
                            }}
  
                            />
                            { companyNameError && (
                              <p className="text-red-500 text-sm">
                                {companyNameError}
                              </p>
                            )}
                            </>
                          ) : (
                            <div className="flex items-center p-2">
                              <div className="px-4">
                                <MdCorporateFare size={25} />
                              </div>
                              <div className="capitalize">
                                <span>{fullName || "NA"}</span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className=" p-2 md:p-0">
                        {dropDownValue === selectedVal ||
                          isErrorMessage ||
                          // proprietorRadioValue === "Proprietor" ||
                          (proprietorRadioValue === "Proprietor" &&
                            radioValue !== 'GST Number'
                          ) ||
                          anotherMode ? (
                          <></>
                        ) : (
                          <label>
                            <b>Date of Incorporation</b>
                          </label>
                        )}
                        {anotherMode ||
                          // proprietorRadioValue === "Proprietor"
                          (proprietorRadioValue === "Proprietor" &&
                            radioValue !== 'GST Number'
                          )
                          ? (
                            <TextInput
                              width="md:w-[300px]"
                              type="date"
                              label={<b>Date of Incorporation</b>}
                              id={"coDateofIncorporation"}
                              value={dateOfIncorporationValue}
                              onChange={(e) => {
                                setDateOfIncorporationValue(e.target.value);
                                dispatch(
                                  coUserDataReducer({
                                    doi: changedateformat(e.target.value),
                                  })
                                );
                              }}
                              placeholder={"Date of Incorporation"}
                              icon={<CalendarMonthIcon size={25} />}
                            />
                          ) : (
                            <></>
                          )}
                        {(isErrorMessage &&
                          (radioValue === "Ckyc" || radioValue === "Cin")) ||
                          (radioValue === "Ckyc" && anotherMode) ||
                          (radioValue === "Cin" && anotherMode) ? (
                          <TextInput
                            width="md:w-[300px]"
                            label={<b>PAN No. of Entity</b>}
                            placeholder={"Ex. AIYPM1234J"}
                            value={panValue}
                            onChange={panChangeHandler}
                            success={
                              panValidationError || panValue?.length <= 0
                                ? false
                                : true
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {panValidationError && (
                          <p className="text-red-500 text-sm">
                            {panValidationError}
                          </p>
                        )}
                        {dropDownValue === selectedVal ||
                          isErrorMessage ||
                          anotherMode
                          ? ""
                          : (proprietorRadioValue !== "Proprietor" || radioValue === 'GST Number' )  && (
                            <div className="flex items-center p-2">
                              <div className="px-4">
                                <CalendarMonthIcon size={25} />
                              </div>
                              <div className="capitalize">
                                <span>{dob || doi || "NA"}</span>
                              </div>
                            </div>
                          )}
                      </div>
                      {/* ADDRESS FIELDS START */}
                      <div className="p-2 md:p-0">
                        <label>
                          <b>Registered Address</b>
                        </label>
                        {dropDownValue === selectedVal ||
                          isErrorMessage ||
                          // proprietorRadioValue === "Proprietor" ||
                          (proprietorRadioValue === "Proprietor" &&
                            radioValue !== 'GST Number'
                          ) ||
                        anotherMode || isAryaLiteServiceUsed  ? (
                          <>
                            <div>
                            <TextInput
                              label={"Address"}
                              id={"Address"}
                              // isIcon={true}
                              // width="md:w-[300px]"
                              placeholder={"Enter Address"}
                              value={addressValue}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (!addressOnKeyUpCheck(newValue)) {
                                setAddressValue(newValue);
                                
                                dispatch(
                                  coUserDataReducer({
                                    permanentAddress: newValue,
                                  })
                                );
                              }
                              }}
                              icon={<MdOutlineLocationOn size={25} />}
                              onKeyDown={(e) => {
                                addressOnKeyUpCheck(addressValue); // Run validation on keyDown
    
                              }}
                            />
                             { addressError && (
                                <p className="text-red-500 text-sm">
                                  {addressError}
                                </p>
                              )}
                            </div>
                            <div className="flex justify-between gap-[10px]">
                            <div>
                              <TextInput
                                label={"State"}
                                id={"State"}
                                width="md:w-[250px]"
                                placeholder={"Enter State"}
                                value={editableState}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (!stateOnKeyUpCheck(newValue)) {
                                  setEditableState(newValue);
                                  dispatch(
                                    coUserDataReducer({
                                      permanentState: newValue,
                                    })
                                  );
                                }
                                }}
                                icon={<MdOutlineLocationOn size={25} />}
                                onKeyDown={(e) => {
                                  stateOnKeyUpCheck(editableState); // Run validation on keyDown
      
                                }}
                              />
                               { stateError && (
                                  <p className="text-red-500 text-sm">
                                    {stateError}
                                  </p>
                                )}
                              </div>
                              <div>
                              <TextInput
                                label={"City"}
                                id={"City"}
                                width="md:w-[250px]"
                                placeholder={"Enter City"}
                                value={editableCity}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (!cityOnKeyUpCheck(newValue)) {
                                  setEditableCity(newValue);
                                  dispatch(
                                    coUserDataReducer({
                                      permanentCity: newValue,
                                    })
                                  );
                                }
                                }}
                                icon={<MdOutlineLocationOn size={25} />}
                                onKeyDown={(e) => {
                                  cityOnKeyUpCheck(editableCity); // Run validation on keyDown
      
                                }}
                              />
                               { cityError && (
                                  <p className="text-red-500 text-sm">
                                    {cityError}
                                  </p>
                                )}

                              </div>
                            </div>
                            <div>
                            <TextInput
                              label={"PinCode"}
                              id={"PinCode"}
                              maxlen={6}
                              type="tel"
                              width="md:w-[300px]"
                              placeholder={"Enter PinCode"}
                              value={editablePinCode}
                              onChange={(e) => {
                                  setPincodeError("")
                                  setEditablePinCode(
                                  pinCodeValidator(e.target.value)
                                );
                                dispatch(
                                  coUserDataReducer({
                                    permanentPincode: pinCodeValidator(
                                      e.target.value
                                    ),
                                  })
                                );
                              
                              }}
                              
                              icon={<MdOutlineLocationOn size={25} />}
                              
                            />
                             { pincodeError && (
                              <p className="text-red-500 text-sm">
                                {pincodeError}
                              </p>
                            )}
                            </div>
                          </>
                        ) : (
                          <div className="flex items-center p-2">
                            <div className="px-4">
                              <MdOutlineLocationOn size={25} />
                            </div>
                            <div className="capitalize">
                              <span>{ maskDetails(permanentAddress) || "NA"}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* ADDRESS FIELDS END */}
                      <div className="flex items-center mt-[10px] ml-[5px] md:ml-0">
                        <input
                          id="link-checkbox1"
                          type="checkbox"
                          value=""
                          checked={isChecked}
                          className="w-5 h-5 rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                          onChange={correspondenceOnchangeHandler}
                        />
                        <label
                          htmlFor="link-checkbox"
                          className="ml-2 text-sm font-medium text-black "
                        >
                          Use this as the correspondence address
                        </label>
                      </div>
                      {!isChecked && (
                        <div className="main_address_container mt-5">
                          <label className="font-bold">
                            Correspondence Address
                          </label>
                          <div className="text_iput">
                            <div>
                            <TextInput
                              label={"Address Line 1"}
                              id="correspondence_address"
                              placeholder={"ex.  ABC Apt, Mumbai-400001"}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (!addressOnKeyUpCheck(newValue)) {
                                setCoAddressLineOne(newValue);
                                dispatch(
                                  coUserDataReducer({
                                    correspondenceAddressLineOne:
                                      newValue,
                                  })
                                );
                              }
                              }}
                              
                              onKeyDown={(e) => {
                                addressOnKeyUpCheck(coAddressLineOne); // Run validation on keyDown
    
                              }}
                              value={coAddressLineOne}
                            />
                            { addressError && (
                            <p className="text-red-500 text-sm">
                              {addressError}
                            </p>
                          )}
                          </div>
                          <div>
                            <TextInput
                              label={"Address Line 2"}
                              id="correspondence_address"
                              placeholder={"ex.  ABC Apt, Mumbai-400001"}
                              
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (!addressOnKeyUpCheck(newValue)) {
                                setCoAddressLineTwo(newValue);
                                dispatch(
                                  coUserDataReducer({
                                    correspondenceAddressLineOne:
                                      newValue,
                                  })
                                );
                              }
                              }}
                              
                              onKeyDown={(e) => {
                                addressOnKeyUpCheck(coAddressLineTwo); // Run validation on keyDown
    
                              }}
                              value={coAddressLineTwo}
                            />
                            { addressError && (
                            <p className="text-red-500 text-sm">
                              {addressError}
                            </p>
                          )}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between items-center gap-5">
                            <div>
                            <TextInput
                              label={"State"}
                              id="correspondence_state"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (!stateOnKeyUpCheck(newValue)) {
                                setCoState(newValue);
                                dispatch(
                                  coUserDataReducer({
                                    correspondenceState: newValue,
                                  })
                                );
                              }
                              }}
                              value={coState}
                              placeholder="ex. Maharashtra"
                              onKeyDown={(e) => {
                               stateOnKeyUpCheck(coState); // Run validation on keyDown
    
                              }}
                            />
                             { stateError && (
                            <p className="text-red-500 text-sm">
                              {stateError}
                            </p>
                          )}
                          </div>
                            <div>
                              <TextInput
                                label={"City"}
                                id="correspondence_city"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (!cityOnKeyUpCheck(newValue)) {
                                  setCoCity(newValue);
                                  dispatch(
                                    coUserDataReducer({
                                      correspondenceCity: newValue,
                                    })
                                  );
                                }
                                }}
                                value={coCity}
                                placeholder="ex. Mumbai"
                                onKeyDown={(e) => {
                                  cityOnKeyUpCheck(coCity); // Run validation on keyDown
      
                                }}
                              />
                              { cityError && (
                              <p className="text-red-500 text-sm">
                                {cityError}
                              </p>
                            )}
                            </div>
                          </div>
                          <div className="pincode w-[47%]">
                          <TextInput
                              type="tel"
                              id="correspondence_number"
                              maxlen={"6"}
                              label={"PinCode"}
                              placeholder={"ex.  400001"}
                              onChange={(e) => {
                                setPincodeError("")
                                setCoPinCode(
                                pinCodeValidator(e.target.value)
                              );
                              dispatch(
                                coUserDataReducer({
                                  correspondencePincode: pinCodeValidator(
                                    e.target.value
                                  ),
                                })
                              );
                            
                            }}
                              
                              
                              value={coPinCode}
                              
                            />
                            { pincodeError && (
                            <p className="text-red-500 text-sm">
                              {pincodeError}
                            </p>
                          )}
                          </div>
                          <p className="text-red-500 text-sm">{""}</p>
                          <div className="flex items-center mt-2">
                            <input
                              id="link-checkbox2"
                              type="checkbox"
                              value=""
                              checked={isConfirmChecked}
                              onChange={confirmcheckedOnChangeHandler}
                              className="w-5 h-5 rounded border-gray-300 text-red-600 ring-0 focus:ring-0"
                            />
                            <label
                              htmlFor="link-checkbox"
                              className="ml-2 text-sm font-medium text-black mt-2"
                            >
                              I confirm that this address is correct as per my
                              knowledge
                            </label>
                          </div>
                        </div>
                      )}

                      <p className="text-red-500 text-sm" id="errorMsg">
                        {errorMessage + (Object.keys(errors).filter(item => errors[item] !== null).length > 0 ? ", also check the fields: " + Object.keys(errors).filter(item => errors[item] !== null).join(",") : " ")}
                      </p>
                    </div>
                    <div className="md:w-[235px] mt-[10px]  md:mr-auto">
                      <CommonButton
                        arrow
                        filled
                        onClickHandler={continueHandler}
                        label="Continue"
                        disabled= {companyNameError || addressError || stateError || cityError || pincodeError ? true : false}
                      />
                    </div>
                  </BottomCard>
                </div>
              </MsHome>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CorporateAddressScreen;


// correspondenceAddressLineOne
// {!isChecked && (
//   <div className="main_address_container mt-5">
//     <label className="font-bold">
//       Correspondence Address
//     </label>
//     <div className="text_iput">
//       <TextInput
//         label={"Address Line 1"}
//         id="correspondence_address"
//         placeholder={"ex.  ABC Apt, Mumbai-400001"}
//         onChange={(e) => {
//           setCoAddressLineOne(e.target.value);
//           dispatch(
//             coUserDataReducer({
//               correspondenceAddressLineOne:
//                 e.target.value,
//             })
//           );
//         }}
//         value={coAddressLineOne}
//       />
//       <TextInput
//         label={"Address Line 2"}
//         id="correspondence_address"
//         placeholder={"ex.  ABC Apt, Mumbai-400001"}
//         onChange={(e) => {
//           setCoAddressLineTwo(e.target.value);
//           dispatch(
//             coUserDataReducer({
//               correspondenceAddressLineTwo:
//                 e.target.value,
//             })
//           );
//         }}
//         value={coAddressLineTwo}
//       />
//     </div>
//     <div className="flex flex-row justify-between items-center gap-5">
//       <TextInput
//         label={"State"}
//         id="correspondence_state"
//         onChange={(e) => {
//           setCoState(e.target.value);
//           dispatch(
//             coUserDataReducer({
//               correspondenceState: e.target.value,
//             })
//           );
//         }}
//         value={coState}
//         placeholder="ex. Maharashtra"
//       />
//       <TextInput
//         label={"City"}
//         id="correspondence_city"
//         onChange={(e) => {
//           setCoCity(e.target.value);
//           dispatch(
//             coUserDataReducer({
//               correspondenceCity: e.target.value,
//             })
//           );
//         }}
//         value={coCity}
//         placeholder="ex. Mumbai"
//       />
//     </div>
//     <div className="pincode w-[47%]">
//       <TextInput
//         type="tel"
//         id="correspondence_number"
//         maxlen={"6"}
//         label={"PinCode"}
//         placeholder={"ex.  400001"}
//         onChange={(e) => {
//           setCoPinCode(pinCodeValidator(e.target.value));
//           dispatch(
//             coUserDataReducer({
//               correspondencePincode: e.target.value,
//             })
//           );
//         }}
//         value={coPinCode}
//       />
//     </div>
//     <p className="text-red-500 text-sm">{""}</p>
//     <div className="flex items-center mt-2">
//       <input
//         id="link-checkbox2"
//         type="checkbox"
//         value=""
//         checked={isConfirmChecked}
//         onChange={confirmcheckedOnChangeHandler}
//         className="w-5 h-5 rounded border-gray-300 text-red-600 ring-0 focus:ring-0"
//       />
//       <label
//         htmlFor="link-checkbox"
//         className="ml-2 text-sm font-medium text-black mt-2"
//       >
//         I confirm that this address is correct as per my
//         knowledge
//       </label>
//     </div>
//   </div>
// )}
