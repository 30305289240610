import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import styles from "./UploadDocumnetDrawer.module.css";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fileUpload,
  formSixtyDocument,
  formSixtyDocumentName,
  previewIamgeURL,
} from "../../Redux/otherDocsSlice";
import { allowedFileSize } from "../../../Router";
import { showGlobalError } from "../../Redux/globalErrorSlice";
const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      "@media (min-width: 500px)": {
        width: "50%",
        margin: "auto",
      },
    },
  },
});

const UploadDocumentDrawer = ({
  onClose = () => {},
  acceptFileType = "image/jpeg,image/png,image/x-eps,image/jpg,image/heic",
  isFormSixty = false,
  isNavigate = false,
  isPdfAllowed = true,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    bottom: true,
  });
  const { channel } = useSelector(
    (state) => state.partnerChannelNameSlice.travelUserDetails
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    onClose(open);
  };

  const uploadFrontFileHandler = (e) => {
    if (isFormSixty && e.target.files[0]?.type !== "application/pdf") {
      onClose(false);
      return dispatch(
        showGlobalError("Only PDF is allowed. Supported file format is PDF")
      );
    }
    if (!isPdfAllowed && e.target.files[0]?.type === "application/pdf") {
      onClose(false);
      return dispatch(
        showGlobalError(
          "PDF is not allowed. Supported file formats are png, jpeg, jpg and heic."
        )
      );
    }
    if(!['image/jpeg','image/png','image/jpg','image/heic'].includes(e.target.files[0]?.type)) {
      onClose(false);
      return dispatch(
        showGlobalError(
          "Supported file formats are png, jpeg, jpg and heic."
        )
      );
    }
    if (e.target.files[0].size / 1000 > allowedFileSize) {
      onClose(false);
      return dispatch(
        showGlobalError(
          "File size exceeds maximum limit. Maximum allowed file size is 10 MB"
        )
      );
    }
    if (e.target.files[0]) {
      if (isFormSixty) {
        dispatch(formSixtyDocument(URL.createObjectURL(e.target.files[0])));
        dispatch(formSixtyDocumentName(e.target.files[0].name));
      } else {
        // if (isNavigate) navigate("/uploadDocument");
        if (isNavigate){
          if(channel === 'travel'){
            navigate("/trUploadDocument");
          } else {
            navigate("/uploadDocument");
          }
        } 
        dispatch(fileUpload({ frontFile: e.target.files[0].name }));
        dispatch(
          previewIamgeURL({
            frontImageURL: URL.createObjectURL(e.target.files[0]),
          })
        );
      }
      onClose(false);
    }
  };

  const list = (anchor) => (
    <Box
      className={styles["drawer"]}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
    >
      <p>{`Upload ${channel === "travel" ? 'First Page': 'front document'} from`}</p>
      <div className="p-2"></div>
      <div className="flex items-center gap-10">
        <div>
          <IconButton
            aria-label="upload picture"
            component="label"
            sx={{
              border: "1px solid var(--primary-color)",
            }}
          >
            <input
              hidden
              accept={acceptFileType}
              type="file"
              onChange={uploadFrontFileHandler}
            />
            <CollectionsOutlinedIcon sx={{ color: "var(--primary-color)" }} />
          </IconButton>

          <p>Gallery</p>
        </div>
        {/* <div>
          <IconButton
            aria-label="upload picture"
            component="label"
            sx={{
              border: "1px solid var(--primary-color)",
            }}
          >
            <input hidden accept="image/*" type="file" />
            <CameraAltOutlinedIcon sx={{ color: "var(--primary-color)" }} />
          </IconButton>
          <p>Camera</p>
        </div> */}
      </div>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor, index) => (
        <React.Fragment key={Math.random() + index}>
          <Drawer
            onClose={toggleDrawer(anchor, false)}
            anchor={anchor}
            open={state[anchor]}
            classes={classes}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default UploadDocumentDrawer;
